<!-- Deprecated - Was used to sign up PREMIUM_TESTER which we no longer do. The flow is from Basic -> Premium now -->
<template>
  <v-container fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex xl4 md6 sm8 xs12>
        <v-slide-y-transition appear mode="out-in">
          <v-alert v-if="success" class="elevation-6" prominent type="success">
            <template v-slot:prepend>
              <v-btn class="mr-6 ml-2 highlight-pulse" icon>
                <v-icon x-large>mdi-check-circle</v-icon>
              </v-btn>
            </template>
            <h3>Välkommen till Uthyrd!</h3>
            <p>Innan du kan sätta igång behöver du bara bekräfta din e-postadress. Ett mejl har
              skickats till dig <b>({{ loginCredentials.email }})</b> med en länk som du kan använda för
              att komma igång.</p>
          </v-alert>
          <v-card v-else key="register-form" class="elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>
                <span>Skapa ett nytt konto</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <div class="text-center my-3">
                <logo-component class="align-middle" color="#837fb6" height="96px"
                                width="96px"></logo-component>
                <h4 class="secondary--text mt-2">Full koll på det du hyr ut - allt samlat på ett ställe och på dina
                  villkor</h4>
              </div>
              <v-form
                  ref="form"
                  v-model="validForm"
                  @submit.prevent
              >
                <v-text-field ref="emailInput" v-model="loginCredentials.email" :rules="emailRules"
                              autofocus
                              label="E-post"
                              name="email"
                              data-cy="emailField"
                              prepend-icon="mdi-account-outline"
                              required
                              type="text"
                              @keyup.enter="registerUser"></v-text-field>
                <password-input-component
                    id="password"
                    :rules="requiredRules" :value.sync="loginCredentials.password"
                    icon="mdi-lock-outline"
                    label="Lösenord"
                    cy-tag="passwordField"
                    @keydown="clearErrorMsg"
                    @focus="showConfirmPassword = true"
                    @keyupEnter="registerUser"></password-input-component>
                <v-slide-y-transition>
                  <div v-show="showConfirmPassword">
                    <password-input-component
                        :allow-visible="false"
                        :rules="requiredRules"
                        :value.sync="confirmPassword"
                        @keydown="clearErrorMsg"
                        icon="mdi-lock-reset"
                        cy-tag="repeatPasswordField"
                        label="Bekräfta lösenord"
                        @keyupEnter="registerUser"
                    ></password-input-component>
                    <v-checkbox
                        data-cy="complianceCheck"
                        v-model="compliance" :rules="[v => v || 'Obligatoriskt fält']"
                        class="mb-3"
                        required>
                      <template v-slot:label>
                        <div>
                          Jag godkänner villkoren i Uthyrds
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                  v-on="on"
                                  href="/allmanna_villkor.pdf"
                                  @click.stop
                                  target="_blank"
                              >
                                användaravtal
                              </a>
                            </template>
                            Läs allmänna villkor
                          </v-tooltip>
                          och att mina personuppgifter behandlas i enlighet med gällande
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <a
                                  v-on="on"
                                  href="/personuppgiftspolicy.pdf"
                                  @click.stop
                                  target="_blank"
                              >
                                personuppgiftspolicy
                              </a>
                            </template>
                            Läs personuppgiftspolicy
                          </v-tooltip>
                        </div>
                      </template>
                    </v-checkbox>
                  </div>
                </v-slide-y-transition>
                <v-alert transition="slide-x-transition" :value="showError" class="elevation-1" type="error"
                         border="left" colored-border>{{ errorMsg }}
                </v-alert>
                <div v-if="!isInIframe" class="text-center mt-2"><a :disabled="loading" href="/login">Har du
                  redan ett konto?
                  Logga in</a>
                </div>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn data-cy="registerUserBtn" :loading="loading" class="highlight-pulse" color="primary" @click="registerUser">
                Skapa konto
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-slide-y-transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import LogoComponent from "../../components/global/LogoComponent";
import api from "../../api/api";
import Axios from "axios";
import PasswordInputComponent from "../../components/inputs/PasswordInputComponent";

export default {
  components: {PasswordInputComponent, LogoComponent},
  data: () => ({
    loginCredentials: {
      email: "",
      password: ""
    },
    confirmPassword: "",
    showConfirmPassword: false,
    loading: false,
    validForm: false,
    errorMsg: "",
    success: false,
    compliance: false,
    emailRules: [
      v => !!v || 'Du måste ange en e-postadress',
      v => /.+@.+/.test(v) || 'Din angivna e-postadress är inte giltig'
    ],
    requiredRules: [
      v => !!v || 'Obligatoriskt fält'
    ]
  }),
  computed: {
    passwordsMatch()
    {
      return this.loginCredentials.password === this.confirmPassword
    },
    isInIframe()
    {
      try
      {
        return window.self !== window.top;
      }
      catch (e)
      {
        return true;
      }
    },
    showError()
    {
      return this.errorMsg.length > 1
    }
  },
  methods: {
    clearErrorMsg()
    {
      this.errorMsg = ""
    },
    async registerUser()
    {
      this.$refs.form.validate()
      if (this.validForm)
      {
        if (!this.passwordsMatch)
        {
          this.errorMsg = "Lösenorden du angett matchar inte. Vänligen kontrollera dina uppgifter"
          return
        }
        this.loading = true
        this.clearErrorMsg()
        // custom API call here to handle unauthorized when email or password is wrong
        try
        {
          let response = await Axios.post(api.BASE_URL + api.API + api.ENDPOINTS.USERACCOUNT, this.loginCredentials,
              {
                withCredentials: true
              })
          let authResponse = response.data
          if (authResponse)
          {
            this.success = true
          }
        }
        catch (error)
        {
          // Treat network errors without responses as 500s.
          const status = error.response ? error.response.status : 500
          if (status === 403)
          { // Forbidden - Used when email is used or invalid
            this.errorMsg = "Den e-postadress du angett har redan ett aktivt konto"
            this.$refs.emailInput.focus()
          }
          else
          {
            this.errorMsg = "Kunde inte kommunicera med servern. Vänligen kontrollera dina nätverksinställningar"
          }
        }
        this.loading = false
      }
    },
  }
}
</script>

<style scoped>
.align-middle {
  vertical-align: middle;
}

.toolbar-logo {
  position: absolute;
  right: 20px;
}

.highlight-pulse {
  box-shadow: 0 0 0 rgba(255, 228, 234, 0.65);
  animation: highlight-pulse 2s infinite;
}

.highlight-pulse:hover {
  animation: none;
}

@-webkit-keyframes highlight-pulse {
  0% {
    box-shadow: 0 0 0 rgba(255, 228, 234, 0.65);
  }
  70% {
    -webkit-box-shadow: 0 0 0 16px rgba(196, 137, 150, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
  }
}

@keyframes highlight-pulse {
  0% {
    -moz-box-shadow: 0 0 0 rgba(255, 228, 234, 0.65);
    box-shadow: 0 0 0 rgba(255, 228, 234, 0.8);
  }
  70% {
    -moz-box-shadow: 0 0 0 16px rgba(196, 137, 150, 0);
    box-shadow: 0 0 0 16px rgba(196, 137, 150, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
    box-shadow: 0 0 0 0 rgba(196, 137, 150, 0);
  }
}


.pulse-up {
  -webkit-animation: pulse-up 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
  animation: pulse-up 0.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate both;
}

@-webkit-keyframes pulse-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes pulse-up {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}
</style>
