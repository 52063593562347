<template>
  <v-dialog v-model="dialogProp" eager max-width="800px" persistent scrollable>
    <AccountSubscriptionUpgradeComponent :user-id="stateUser.id" @cancel="dialogProp = false"
                                         :subscription.sync="subscription"></AccountSubscriptionUpgradeComponent>
  </v-dialog>
</template>

<script>
import AccountSubscriptionUpgradeComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionUpgradeComponent.vue";


export default {
  components: {AccountSubscriptionUpgradeComponent},
  props: {
    dialog: Boolean,
    subscription: Object,
  },
  computed: {
    stateUser() {
      return this.$store.state.user
    },
    dialogProp: {
      get: function () {
        return this.dialog;
      },
      set: function (newValue) {
        this.$emit('update:dialog', newValue)
      }
    },
    accountSubscription: {
      get: function () {
        return this.subscription;
      },
      set: function (newValue) {
        this.$emit('update:subscription', newValue)
      }
    },
  },
}
</script>
