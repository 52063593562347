<template>
  <div>
    <v-tabs
        v-model="tab"
        color="primary"
        grow
    >
      <v-tab>
        <v-icon left>mdi-web</v-icon> Webbplats
      </v-tab>
      <v-tab>
        <v-icon left>mdi-calendar-question</v-icon> Bokningsmodul
      </v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-tabs-items class="mt-4" v-model="tab">
      <v-tab-item>
        <listings-tab-component :booking-object="bookingObject"></listings-tab-component>
      </v-tab-item>
      <v-tab-item>
        <external-booker-admin-component :booking-object="bookingObject"></external-booker-admin-component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ExternalBookerAdminComponent from "@/components/bookingobject/tabs/presentation/ExternalBookerAdminComponent";
import ListingsTabComponent from "@/components/bookingobject/tabs/presentation/ListingsTabComponent.vue";

export default {
  components: {ListingsTabComponent, ExternalBookerAdminComponent},
  data: () => ({
    tab: false,
  }),
  props: {
    bookingObject: Object,
  },
  methods: {}
}
</script>
