var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item.divider)?_c('v-divider'):_c('v-list-item',{key:_vm.item.name + _vm.item.id,staticClass:"mb-3",attrs:{"ripple":"","to":_vm.disableNavigation ? null : {name: 'conversationDetails', params: {id: _vm.item.id}}}},[_c('v-list-item-icon',{staticStyle:{"display":"block","height":"155px","margin-top":"5px","margin-bottom":"0","padding-top":"15px"}},[(_vm.item.archived === 1)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mb-2 ml-1",attrs:{"color":"grey"}},on),[_vm._v(" mdi-folder-remove-outline ")])]}}],null,false,2026061900)},[_c('span',[_vm._v("Denna konversation är arkiverad")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",staticStyle:{"margin-left":"-2px"},attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.confirmArchive(_vm.item)}}},on),[_c('v-icon',{attrs:{"color":"error","dark":""}},[_vm._v("mdi-delete-outline")])],1)]}}])},[_c('span',[_vm._v("Arkivera konversation")])]),(_vm.item.customer)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('initials-avatar-component',{staticClass:"mb-1",attrs:{"imgSrc":_vm.getImageUrl(_vm.item.customer),"size":32,"firstname":_vm.item.customer.firstname,"lastname":_vm.item.customer.lastname}})],1)]}}],null,false,1858639446)},[_c('span',[_vm._v(_vm._s(("Kund - " + (_vm.item.customer.firstname) + " " + (_vm.item.customer.lastname))))])]):_vm._e(),(_vm.item.bookingObject)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_c('initials-avatar-component',{attrs:{"imgSrc":_vm.getImageUrl(_vm.item.bookingObject),"size":32,"fullname":_vm.item.bookingObject.name}})],1)]}}],null,false,4099980593)},[_c('span',[_vm._v(_vm._s(("Uthyrningsobjekt - " + (_vm.item.bookingObject.name))))])]):_vm._e()],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('v-list-item-subtitle',[_c('b',[_vm._v(_vm._s(_vm.item.latestCreatedMessage.author))])]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.item.latestCreatedMessage.body))])],1),_c('v-list-item-action',[_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm.$moment(_vm.item.latestCreatedMessage.createdAt).fromNow())+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mt-2",attrs:{"color":_vm.item.numberOfUnread > 0 ? 'error' : 'grey'}},on),[_vm._v(" "+_vm._s(_vm.item.numberOfUnread > 0 ? "mdi-message-alert-outline" : "mdi-message-outline")+" ")])]}}])},[_c('span',[_vm._v(_vm._s(("Det finns " + (_vm.item.numberOfUnread) + " olästa meddelanden i denna konversation")))])]),(_vm.item.bookingPeriod)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mt-2",attrs:{"color":_vm.bookingPeriodStatuses.STATUSES[_vm.item.bookingPeriod.status].color}},on),[_vm._v(" "+_vm._s(_vm.bookingPeriodStatuses.STATUSES[_vm.item.bookingPeriod.status].icon)+" ")])]}}],null,false,1263281416)},[_c('span',[_vm._v(_vm._s(((_vm.item.bookingPeriod.name) + " | " + (_vm.bookingPeriodStatuses.STATUSES[_vm.item.bookingPeriod.status].text))))])]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }