<template>
  <div>
    <website-admin-component :booking-object="bookingObject"></website-admin-component>
    <visit-logs-component :booking-object-id="bookingObject.id"></visit-logs-component>
  </div>
</template>

<script>

import WebsiteAdminComponent from "@/components/bookingobject/tabs/presentation/WebsiteAdminComponent";
import VisitLogsComponent from "@/components/bookingobject/tabs/presentation/VisitLogsComponent.vue";

export default {
  components: {VisitLogsComponent, WebsiteAdminComponent},
  props: {
    bookingObject: Object
  }
}
</script>
