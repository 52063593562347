<template>
  <v-container fill-height fluid>
    <v-layout align-center justify-center>
      <v-flex xl6 lg10 md12>
        <v-slide-x-transition appear mode="out-in">
          <div v-if="loading">
            <UthyrdLoaderComponent></UthyrdLoaderComponent>
          </div>
          <div v-else>
            <h3 class="mb-6">
              Uppgradera till Uthyrd Premium
            </h3>
            <AccountSubscriptionUpgradeComponent
                @cancel="onCancel"
                v-if="subscription"
                :user-id="user.id"
                :subscription="subscription"></AccountSubscriptionUpgradeComponent>
          </div>
        </v-slide-x-transition>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import api from "../../api/api";
import AccountSubscriptionUpgradeComponent
  from "@/components/useraccount/accountsubscription/AccountSubscriptionUpgradeComponent.vue";
import UthyrdLoaderComponent from "@/components/global/UthyrdLoaderComponent.vue";
import constants from "@/util/constants";

export default {
  components: {UthyrdLoaderComponent, AccountSubscriptionUpgradeComponent},
  data: () => ({
    user: null,
    subscription: null,
    loading: false,
  }),
  async mounted() {
    await this.getBasicUserAccount()
  },
  computed: {
    adminUrl() {
      return  (process.env.NODE_ENV === 'production' ?  constants.BASIC_PROD_URL : constants.BASIC_DEV_URL) + "/admin"
    }
  },
  methods: {
    async getUserAccountSubscription() {
      this.loading = true
      let serverResponse = await api.getFromEndpoint(api.ENDPOINTS.USERACCOUNTS + "/" + this.user.id + "/" + api.ENDPOINTS.ACCOUNTSUBSCRIPTION + "?includeHistory=true")
      if (serverResponse) {
        this.subscription = serverResponse
      }
      this.loading = false
    },
    async getBasicUserAccount() {
      this.loading = true
      let authResponse = await api.getFromEndpoint(api.ENDPOINTS.AUTH)
      if (authResponse) {
        this.user = authResponse
        this.$nextTick(() => this.getUserAccountSubscription())
      } else {
        await this.$router.replace({name: "login"})
      }
      this.loading = false
    },
    onCancel() {
      this.loading = true
      location.href = this.adminUrl
    }
  }
}
</script>
